import { updateQuoteStatusAPi } from "../services/configService";

export const globalHandleChange = (e, dataSetter, errorSetter) => {
  const { name, value } = e.target;
  dataSetter((prev) => ({
    ...prev,
    [name]: value,
  }));

  errorSetter((prev) => ({
    ...prev,
    [name]: "",
  }));
};

export const globalHandleError = (key, setter, msg) => {
  setter((prev) => ({
    ...prev,
    [key]: msg,
  }));
};

export const globalHandlePhone = (e, v, setter, setError, error, e1, e2) => {
  let x = e;
  let num = x.split(" ")[1]?.replace("-", "");
  setter((pre) => ({
    ...pre,
    mobile_no: num,
    mobile_code: v.dialCode,
  }));

  if (error && (error[e1] || error[e2])) {
    setError((prevError) => ({
      ...prevError,
      [e1]: null,
      [e2]: null,
    }));
  }
};

export const handleChangeGlobal = (
  e,
  dataSetter,
  errorSetter,
  error,
  v1,
  v2 = null,
  capitalize = false
) => {
  const { name, value } = e.target;
  dataSetter((prev) => ({
    ...prev,
    [name]: capitalize ? value.toUpperCase() : value,
  }));

  if (error && (error[v1] || error[v2])) {
    errorSetter((prevError) => ({
      ...prevError,
      [v1]: null,
      [v2]: null,
    }));
  }
};

export const currencyFormatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});

export function setRole(roleId, subroleId) {
  let id = roleId;
  if (roleId === 3) {
    if (subroleId === 1) {
      id = 3;
    } else if (subroleId == 2) {
      id = 4;
    }
  }
  return id;
}

export const handleChangeQueryStatus = async (status, remark, id, setError) => {
  console.log("status ", status);
  try {
    const sendData = {
      status: status,
      remarks: remark,
    };
    if (remark && remark.length < 5) {
      return 204;
    }
    const res = await updateQuoteStatusAPi(sendData, id, setError);
    if (res !== 204 && res !== 406) {
      return 200;
    }
  } catch (error) {
    console.log(error.message);
  }
};

export function findPercent(total, amount, inputAmount) {
  if (inputAmount > 0) {
    let pt = (amount * 100) / total;
    return pt.toFixed(2);
  } else {
    return 0;
  }
}

export function downloadAttachment(url, filename) {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.target = "_blank";
  anchor.download = filename;
  anchor.click();
  anchor.remove();
}

export function uploadAttachment(type) {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = { type };
  document.body.appendChild("input");
  input.click();
  input.addEventListener("change", handleFileSelections);
}

function handleFileSelections(event) {
  const selectedFile = event.target.files;
  console.log("32432", selectedFile);
  event.target.parentNode.remveChild(event.target);
  event.target.value = null;
}

export function setSessionStorage(data, name) {
  const jsonData = JSON.stringify(data);
  sessionStorage.setItem(name, jsonData);
}

export function getSessionStorage(name) {
  const store = sessionStorage.getItem(name);
  if(store){
    const data = JSON.parse(store);
    return data;
  }
  return null;
}

export function toCapitialize(text) {
  const formattedText =  text ? text.replace(/\b\w/g, (c) => c.toUpperCase()) : '';
  return formattedText
}
