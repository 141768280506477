import { Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import ProfileMenu from "./ProfileMenu";
import useAuth from "../../hooks/useAuth";


const Header = () => {
  const { auth } = useAuth();
  const theme = useTheme()
  const { isAuthenticated } = auth;
  console.log("auth",auth);
 
  const u= auth?.menu_list?.map((item)=>(
    item.path
  ))
  const y= auth?.menu_list?.map((item)=>(
    item.menu_name
  ))
  console.log("xxx",u,y);
  
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <>
    {isSmallScreen ? (
      <>
    <Grid
      container
      sx={{
        backgroundColor: "rgb(163 167 168 / 50%)",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(10px)",
      }}
  
      zIndex={99}
      
      height={76}
      alignItems={"center"}
      paddingX={2}
      direction="row"
      justifyContent="flex-start"
      
    >
      
      <Grid item xs={4}>
        <Link to="/">
        <img src="../images/zealazerbaijanlogo1.png" alt="logo" height={70} />
        </Link>
      </Grid>
      {/* <Grid item md={4} xs={4}>
        <Grid
          container
          paddingLeft={"12%"}
          alignItems={"center"}
          className="header-nav"
          sx={{
            color: "white",
            fontWeight: "600",
            fontFamily: "Inter, sans-serif",
            marginBottom: "7px",
          }}
          gap={6}
        >
          {isAuthenticated && (
            <>
          <Grid item sx={{ cursor: "pointer" }} className="nav-item-anim">
            <NavLink to="/">Home </NavLink>
          </Grid>
            <Grid item sx={{ cursor: "pointer" }} className="nav-item-anim">
              <NavLink to={"/dashboard"}>Dashboard</NavLink>
            </Grid>
            </>
          ) 
        }
        </Grid>
      </Grid> */}
   <Grid item xs={2}></Grid>
      {isAuthenticated ? (
        <Grid item xs={5}>
          <ProfileMenu />
        </Grid>
      ) : (
        <Grid
          item
          xs={5}
          display={"flex"}
          gap={1.5}
          justifyContent={"flex-end"}
        >
          {/* <Link to="/signup">
            <Button
              sx={{
                float: "right",
                backgroundColor: "#FFA903",
                color: "rgba(0,0,0,0.8)",
                fontWeight: "600",
                borderRadius: "2px",
                '&:hover': {
                  backgroundColor: "#ff9933"
                },
              }}
              variant="contained"
            >
              Register
            </Button>
          </Link> */}
          {/* <Button
              sx={{
                float: "right",
                backgroundColor: "#FFA903",
                color: "rgba(0,0,0,0.8)",
                fontWeight: "600",
                borderRadius: "2px",
                '&:hover': {
                  backgroundColor: "#ff9933"
                },
              }}
              variant="contained"
            >
              Enquiry
            </Button> */}
          <Link to="/login">
            <Button
              sx={{
                float: "right",
                marginRight: "10px",
                backgroundColor: "#ffffff",
                color: "rgba(0,0,0,0.8)",
                fontWeight: "600",
                borderRadius: "2px",
                '&:hover': {
                  backgroundColor: "#e0e0e0", 
                  color: "rgba(0,0,0,1)", 
              }}}
              variant="contained"
            >
              Login
            </Button>
          </Link>
        </Grid>
      )}
    </Grid>
    </>
    ):(
      <Grid
      container
      sx={{
        backgroundColor: "rgb(163 167 168 / 50%)",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(5px)",
      }}
      // position={"fixed"}
      zIndex={99}
      height={76}
      alignItems={"center"}
      paddingX={10}
    >
      <Grid item md={3}>
        <Link to="/">
          <img src="../images/zealazerbaijanlogo10.png" alt="logo" height={70} />
        </Link>
      </Grid>
      <Grid item md={6.5}>
        <Grid
          container
          paddingLeft={"12%"}
          alignItems={"center"}
          className="header-nav"
          sx={{
            color: "white",
            fontWeight: "600",
            fontFamily: "Inter, sans-serif",
            marginBottom: "7px",
          }}
          gap={6}
        >
          {isAuthenticated && (
            <>
          {/* <Grid item sx={{ cursor: "pointer" }} className="nav-item-anim">
            <NavLink to="/">Home </NavLink>
          </Grid> */}
            {/* <Grid item sx={{ cursor: "pointer" }} className="nav-item-anim">
              <NavLink to={"/dashboard"}>Dashboard</NavLink>
            </Grid> */}
            </>
          ) 
        }
        </Grid>
      </Grid>

      {isAuthenticated ? (
        <Grid item md={2.5}>
          <ProfileMenu />
        </Grid>
      ) : (
        <Grid
          item
          md={2}
          display={"flex"}
          gap={1.5}
          justifyContent={"flex-end"}
        >
          <Link to="/signup">
            <Button
              sx={{
                float: "right",
                
                color: "white",
                fontWeight: "600",
                borderRadius: "2px",
                '&:hover': {
                  backgroundColor: "#64b5f6"
                },
              }}
              variant="contained"
            >
              Register
            </Button>
          </Link>
          <Link to="/login">
            <Button
              sx={{
                float: "right",
                marginRight: "10px",
                backgroundColor: "#ffffff",
                color: "rgba(0,0,0,0.8)",
                fontWeight: "600",
                borderRadius: "2px",
                '&:hover': {
                  backgroundColor: "#e0e0e0", 
                  color: "rgba(0,0,0,1)", 
              }}}
              variant="contained"
            >
              Login
            </Button>
          </Link>
        </Grid>
      )}
    </Grid>

    )}
    </>
  )
}

export default Header;
