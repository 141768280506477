// prettier-ignore
/* eslint-disable */
import React, { lazy, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import useAuth from "./hooks/useAuth";
import { userAuthServer } from "./services/configService";
import Loadable from "./components/Loadable/Loadable";
import AuthContext from "./context/AuthProvider";
import Loader from "./components/loader/Loader";
import { setRole } from "./utils/common";
import ProtectedRoute from "./utils/ProtectedRoute";
import Form from "./pages/common/signUpForm/Layout";
import Layout from "./pages/Layout";
import PageNotFound from "./pages/common/404";
import socketIOClient from 'socket.io-client';
import { SnackbarContext } from "./context/Snackbar";
import Dash from "./pages/common/Dash";
import MainDash from "./pages/admin/dashboard/MainDash";
import { baseURL } from "./services/APIservice";
import Loginform from "./components/form/Loginform";
import CreateCRN from "./pages/finance/create-crn/CreateCRN";
import DateCalendarServerRequest from "./components/calendar/Calendar";
import Usercalendar from "./components/calendar/Usercalendar";
import CreateLedger from "./pages/finance/ledger/CreateLedger";
import MyComponent from "./components/prelogin_date/Datepicker";
import Accordioncard from "./components/prelogin_date/Accordion";
import Customerbooking from "./pages/common/customer_flightbooking/Customerbooking";
import Preloginpayment from "./pages/agent/payment/Preloginpayment";
import Chargeheadmain from "./pages/finance/charge_head/Chargeheadmain";
import Seatinventorymain from "./pages/admin/dashboard/seat_inventory/Seatinventorymain";
import Termandcondition from "./components/policy/Termandcondition";
import Privacy from "./components/policy/Privacy";
import Refund from "./components/policy/Refund";
import Shippingpolicy from "./components/policy/Shippingpolicy";


// import { requestPermission } from "./Push_Notification/PushNotification";
// import { onMessage } from "firebase/messaging";
// import { messaging } from "./Firebase/firebase";


const TermsPage = Loadable(lazy(() => import('./pages/agent/dashboard/terms/page')));
const Profile = Loadable(lazy(() => import('./pages/agent/dashboard/profile')));
const Mybooking = Loadable(lazy(() => import('./pages/agent/dashboard/my_booking/Mybooking')));
const BookingPolicy = Loadable(lazy(() => import('./pages/agent/dashboard/policy/BookingPolicy')));
const Loginregister = Loadable(lazy(() => import('./components/form/Loginregister')));
const HomePage = Loadable(lazy(() => import('./pages/common/Booking/home')));
const Welcome = Loadable(lazy(() => import('./pages/common/welcome')));
const QueryListMain = Loadable(lazy(() => import('./pages/common/query_list/Query_list_main')));
const Createquote = Loadable(lazy(() => import('./pages/admin/dashboard/create_quote/Createquote')));
const CreateCharge = Loadable(lazy(() => import('./pages/finance/create_charge/CreateCharge')));
const PassengerDetails = Loadable(lazy(() => import('./pages/agent/dashboard/passenger-details')));
const FlightInfo = Loadable(lazy(() => import('./pages/sales/dashboard/flight-info/FlightInfo')));
const Lead = Loadable(lazy(() => import('./pages/admin/dashboard/create_lead/Lead')));
const Empdisplaycard = Loadable(lazy(() => import('./pages/admin/dashboard/emp_management/Empdisplaycard')));
const AddDesign = Loadable(lazy(() => import('./pages/admin/dashboard/add_designation/AddDesignation')));
const UserManagementCard = Loadable(lazy(() => import('./pages/admin/dashboard/agent_user_list/UserManagementCard')));
const Leaddisplay = Loadable(lazy(() => import('./pages/admin/dashboard/create_lead/Leaddisplay')));
const PaymentSuccess = Loadable(lazy(() => import('./pages/agent/payment/PaymentSuccess')));
const AdditionalInvoice = Loadable(lazy(() => import('./pages/finance/additional_invoice/AdditionalInvoice')));
const FinBookingPayment = Loadable(lazy(() => import('./pages/finance/booking_payment/VerifyOfflinePayment')));
const UserList = Loadable(lazy(() => import('./pages/sales/dashboard/User-list')));
const Invoices = Loadable(lazy(() => import('./pages/finance/invoices/Invoices')));
const ChargesList = Loadable(lazy(() => import('./pages/finance/create_charge/ChargesList')));

const socket = socketIOClient(baseURL);

function App() {

  const { setAuth } = useAuth();
  const { auth } = useContext(AuthContext);
  console.log("dfd",auth)
  const { getnotifications } = useContext(SnackbarContext);

  let allowedRoles = [];

  
  async function authenticateUser() {
    try {
      setAuth((pre) => ({ ...pre, isLoading: true }));
      const res = await userAuthServer();
      if (res === 401) {
      } else if (res.status) {
        setAuth((pre) => ({
          ...pre,
          id: res?.data?.id ,
          role: setRole(res?.data?.role,res?.data?.sub_role),
          isAuthenticated: res?.data?.isAuthenticated,
          details : res?.data?.details,
          menu_list  : res?.data?.menu_list
        }));
      }
    } catch (error) {
      console.log("error in app.js auth ", error.message);
    } finally {
      setAuth((pre) => ({ ...pre, isLoading: false }));
    }
  }



  useEffect(() => {
    authenticateUser();
    socket.on("notification", ()=>getnotifications('/app.js'));
    // requestPermission();
    // onMessage(messaging, (payload)=>{
    //   alert(payload.notification.body)
    // })
  }, []);

  useEffect(()=>{
    if(!auth.isLoading && auth.isAuthenticated){
      socket.emit("login",{
        id : auth.id
      })
    }
  },[auth])

  if (auth.isLoading) {
    return <Loader />;
  }



  return (
    <React.Suspense fallback={<Loader/>}>
      <Routes>
        <Route path="/dash" element={<Dash />} />
        <Route path="/term-and-condition" element={<Termandcondition />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/refund-policy" element={<Refund />} />
        <Route path="/Shipping-policy" element={<Shippingpolicy />} />
        <Route element={<Layout />}>
          {/* default routes */}
          <Route path="/" element={<Welcome />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/login" element={<Form><Loginform /></Form>} />
          <Route path="/signup" element={<Form><Loginregister /></Form>} />
          <Route path="/calendar" element={<Accordioncard/>} />
          <Route path="/preloginpayment-success" element={<Preloginpayment/>} />
          </Route>
          <Route element={<Dash/>}>

            {/* Agent Routes */}
            <Route element={<ProtectedRoute allowedRoles={allowedRoles.concat([2])} />}>
              <Route path="/payment-success/:id" element={<PaymentSuccess/>} />
<              Route path="policy" element={<BookingPolicy />} />
              <Route path="terms" element={<TermsPage />} />
              <Route path="profile" element={<Profile/>} />
            </Route>
            
            {/* Admin Routes 1 */}
            <Route element={<ProtectedRoute allowedRoles={allowedRoles.concat([1])} />}>
              <Route path="emp-management" element={<Empdisplaycard/>} /> 
              <Route path="add-designation" element={<AddDesign/>} /> 
              <Route path="user-management" element={<UserManagementCard/>} /> 
              
            </Route>

            {/* Sales Routes 3 */}
            <Route element={<ProtectedRoute allowedRoles={allowedRoles.concat([3])} />}>
              <Route path="user-list" element={<UserList/>} />
            </Route>

            {/* Finance list 4 */}
            <Route element={<ProtectedRoute allowedRoles={allowedRoles.concat([4])} />}>
              <Route path="user-list" element={<UserList/>} />
              <Route path="invoices/*">
                <Route index element={<Invoices/>}/>
                <Route path="create-crn" element={<CreateCRN/>}/>
                <Route path="update-invoice" element={<CreateCRN/>}/>
                <Route path="update-invoice" element={<CreateCRN/>}/>
              </Route> 
              <Route path="/additional-invoice" element={<AdditionalInvoice/>} /> 
              <Route path="/booking-payment" element={<FinBookingPayment/>} /> 
              <Route path="/booking-payment/:id" element={<FinBookingPayment/>} /> 
              <Route path="/charge-list/*">
                <Route index element={<ChargesList/>}/>
                <Route path="create-charge" element={<CreateCharge/>}/>
                <Route path="update-charge" element={<CreateCharge/>}/>
              </Route>   
              <Route path="/all-charges" element={<Chargeheadmain/>}/>
              <Route path="/create-ledger" element={<CreateLedger/>}/>
            </Route>

            {/* Admin, Agent and sales */}
            <Route element={<ProtectedRoute allowedRoles={allowedRoles.concat([2,1,3])} />}>
              <Route path="query-list/:id" element={<QueryListMain />} />
              <Route path="query-list" element={<QueryListMain />} />
              <Route path="create-query/*" element={<HomePage />} />
            </Route>

            {/* Admin, agent, sales, and finance */}
            <Route element={<ProtectedRoute allowedRoles={allowedRoles.concat([2,1,3,4])} />}>
              <Route path="dashboard" element={auth.role === 2 ? <HomePage/> : <MainDash/>}/> 
              <Route path="booking/:id" element={<Mybooking />} />
              <Route path="booking">
                <Route index element={<Mybooking />} />
                <Route path="passenger-details" element={<PassengerDetails />} />
              </Route>
            </Route>

            {/* Sales and Admin */}
            <Route element={<ProtectedRoute allowedRoles={allowedRoles.concat([1,3])} />}>
              <Route path="create-quote" element={<Createquote />} />
              <Route path="flight-info" element={<FlightInfo />} /> 
              <Route path="/all-leads/*">
                <Route index element={<Leaddisplay />} />
                <Route path="create-lead" element={<Lead />} />
                
              </Route>
              <Route path="/editcalendar" element={<DateCalendarServerRequest/>}/>
              <Route path="/customer-flightbooking" element={<Customerbooking />} /> 
              <Route path="/seat-inventory" element={<Seatinventorymain />} /> 
            </Route>
          </Route>

          {/* 404  */}
          <Route path="*" element={<PageNotFound />} />
      </Routes>
    </React.Suspense>
  );
}

export default App;
