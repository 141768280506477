import { AuthPOST, DELETE, FORMDATA, GET, POST, PUT } from "./APIservice"


// ----------------- GET --------------------------


export const getAgentProfile = async() => {
    return GET(`/agent/profile`);
}

export const userAuthServer = async() =>{
    return GET('/auth/user-authorize');
}

export const logout = async() => {
    return GET('/auth/logout');
}

export const getQuote = async() => {
    return GET('/admin-employee-user/query');
}

export const getDepartment = async() => {
    return GET('/admin/department')
}

export const getCountry = async() => {
    return GET('/auth/country');
}

export const getState = async(data) =>{
    return GET(`/auth/state/${data}`)
}

export const getCity = async(data) =>{
    return GET(`/auth/city/${data}`)
}

export const getAddressListApi = async() =>{
    return GET(`/agent/address-list`)
}

export const getPaymentBreakDownApi = async(id) =>{
    return GET(`/admin-employee-user/paymentdetails/${id}`)
}

export const getLeadstatus = async() => {
    return GET('/admin-employee/lead-status');
}

export const getLeadsource = async() => {
    return GET('/admin-employee/lead-source');
}

export const getAgent = async() => {
    return GET('/admin-employee/agent');
}

export const getAirport = async() => {
    return GET('/auth/airport');
}

export const getQuotesDetailsApi = async(id) => {
    return GET(`/admin-employee-user/quote/${id}`)
}

export const getFlightInventorydetailsApi = async(id) => {
    return GET(`/admin-employee/inventory-flight-details/${id}`)
}

export const getPassengerListApi = async(id) => {
    return GET(`/admin-employee-user/passenger-query/${id}`)
}
export const getBookingSummary = async() => {
    return GET(`/admin-employee-user/bookingsummary`)
}
export const getNotification = async() => {
    return GET(`/admin-employee-user/notification`)
}

export const clearNotificationApi = async() => {
    return DELETE(`/admin-employee-user/notification`)
}

export const readNotification = async(data) => {
    return PUT(`/admin-employee-user/notification`,data)
}

export const getSettingss = async(id) => {
    return GET(`/admin-employee/settings/${id}`)
}

export const getCustomerbooking = async() => {
    return GET(`/pre-login/booking-details`)
}

export const getChargehead = async() => {
    return GET(`/admin-employee/charge-head`)
}

export const getSeatinventoryreport = async() => {
    return GET(`/admin-employee/seat-inventory`)
}


export const getPrebookingPassengerUpdate = async(data) => {
    return PUT(`/pre-login/booking-details`,data)
}

export const getDisabledate = async(data) => {
    return PUT(`/admin-employee/charge-head`,data)
}



//------------------ POST -----------------------------

export const signupGenrateOtp = async(data,setError) => {
    return AuthPOST('/auth/signup-generate-otp',data,setError)
}

export const updateProfile = async(data) => {
    return POST('/userAuthorization',data)
}

export const getEmailOtp = async(data,setError) => {
    return AuthPOST(`/auth/login-otp-generate`,data,setError)
}

export const loginOtpValidation =async(data,setError) => {
    return AuthPOST('/auth/login-with-otp-validate',data,setError)
}

export const createAgentProfile = async(data,setError)=>{
    return AuthPOST('/auth/signup',data,setError)
}

export const agentLogin = async(data,setError) => {
    return AuthPOST('/auth/login',data,setError);
}
export const agentPayment = async(data) => {
    return POST('/agent/create/orderId',data);
}

export const preloginPayment = async(data) => {
    return POST('/pre-login/initiate-order',data);
}

export const verifyPayment = async(data) => {
    return POST('/agent/verify-payment',data);
}

export const offlinePayApi = async(data,setError) => {
    return POST('/agent/payment-pay',data,setError);
}

export const onewayTicket = async(data)=>{
    return POST('/agent/one-way-ticket',data);
}

export const forgetPassOtpValidate = async(data,setError) => {
    return AuthPOST('/auth/forget-pass-otp-validate',data,setError)
}

export const createQuery = async(data,setError) => {
    return POST('/admin-employee-user/query',data,setError)
}

export const addPassengersApi = async(data,setError) => {
    return POST('/agent/passenger',data,setError)
}

export const addPassengersCSVApi = async(data,setError) => {
    return POST('/agent/passengercsv',data,setError)
}

export const getAcknowledgmenetApi = async(data) => {
    return POST('/admin-employee-user/acknowledgement',data)
}
export const getSaveSettingss = async(data) => {
    return POST('/admin-employee/settings',data)
}

export const getPreLogindetails = async(data,setError) => {
    return POST('/pre-login/booking-details',data,setError)
}

export const getCreateChargeHead = async(data,setError) => {
    return POST('/admin-employee/charge-head',data,setError)
}

export const getPreLoginInitiatePayment = async(data) => {
    return POST('/pre-login/initiate-cashfree-order',data)
}


export const getPreLoginVerifyPayment = async(data) => {
    return POST('/pre-login/verify-cashfree-payment',data)
}





// -------------------- PUT ----------------------

export const updateAgentProfile = async(data,setError)=>{
    return PUT('/agent/profile',data,setError)
}

export const passwordUpdate = async(data) =>{
    return PUT('/auth/forget-password',data)
}

export const updateQuoteStatusAPi = async(data,id,setError) =>{
    return PUT(`/admin-employee-user/query-status/${id}`,data,setError)
}

export const holdDateExtend = async(data,id,setError) =>{
    return PUT(`/admin-employee-user/request-hold-date/${id}`,data,setError)
}

export const updateHoldDateAndTime = async(data,id,setError) =>{
    return PUT(`/admin-employee/quote-date-reschedule/${id}`,data,setError)
}

export const changeProfilePicApi = async(data,setError) => {
    return FORMDATA(`/admin-employee-user/profile-pic`,data,setError,"PUT")
}

export const availableStatus = async(id,data) => {
    return PUT(`/admin-employee/flight-avilable/${id}`,data)
}
// -------------------- DELETE ----------------------



