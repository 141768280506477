import React from 'react'
import DashboardLayout from '../../../../components/dashboard_layout/DashboardLayout'
import Seatinventorylisting from './Seatinventorylisting'

const Seatinventorymain = () => {
  return (
    <DashboardLayout heading='Customer Booking' pathName='Customer Booking'>
    <Seatinventorylisting />
  </DashboardLayout>
  )
}

export default Seatinventorymain