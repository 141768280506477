import React, { useContext, useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionActions from '@mui/material/AccordionActions'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  IconButton,
  FormHelperText,
} from '@mui/material'
import { Cancel, Delete, FlightLand, FlightTakeoff } from '@mui/icons-material'
import PassengerDetails from '../../pages/agent/dashboard/passenger-details'
import Passenger_info from './Passenger_info'
import { handlePay, handlePreloginpayment } from '../../utils/razorpay'
import {
  getCity,
  getCountry,
  getPreLoginInitiatePayment,
  getPreLoginVerifyPayment,
  getPreLogindetails,
  getState,
} from '../../services/configService'
import {
  currencyFormatter,
  getSessionStorage,
  handleChangeGlobal,
  setSessionStorage,
} from '../../utils/common'
import dayjs from 'dayjs'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { load } from '@cashfreepayments/cashfree-js'

import { useNavigate } from 'react-router-dom'
import { SnackbarContext } from '../../context/Snackbar'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,

  boxShadow: 24,
  p: '20px',
}

const Accordioncard = ({}) => {
  let cashfree
  let insitialzeSDK = async function () {
    cashfree = await load({
      mode: 'sandbox',
    })
  }
  insitialzeSDK()
  const navigate = useNavigate()
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext)
  const [booknow, setBooknow] = useState(false)
  const [error, setError] = useState([])
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [passengers, setPassengers] = useState([])
  const [passengerCounts, setPassengerCounts] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [cashFreeAmount, setCashFreeAmount] = useState('')
  // const [error, setError] = useState([])
  const [bookingId, setBookingId] = useState('')
  const [billingdetails, setBillingdetails] = useState({
    name: '',
    email: '',
    phone_no: '',
    country: '',
    state: '',
    city: '',
    pincode: '',
    address: '',
  })
  const [open, setOpen] = React.useState(false)
  const [oJdata, setOJdata] = React.useState({})

  const handleClose = () => setOpen(false)

  const storedDate = getSessionStorage('date') || ''
  const storedTripType = getSessionStorage('tripType') || ''
  const storedAdultPassenger = getSessionStorage('adultPassenger') || 0
  const storedChildPassenger = getSessionStorage('childPassenger') || 0
  const storedInfantPassenger = getSessionStorage('infantPassenger') || 0
  const storedflightFrom = getSessionStorage('flightFrom') || ''
  const storedflightTo = getSessionStorage('flightTo') || ''
  const storedflightFromCode = getSessionStorage('flightFromCode') || ''
  const storedflightToCode = getSessionStorage('flightToCode') || ''
  const storedPrice = getSessionStorage('price') || 0
  const storedflightCode = getSessionStorage('flightCode') || ''
  const storedflightReturnDD = getSessionStorage('departDate') || ''
  const storedflightReturnDA = getSessionStorage('returnDate') || ''
  const storedflightReturnDp = getSessionStorage('goingPrice') || 0
  const storedflightReturnAp = getSessionStorage('returnPrice') || 0
  const storedflightgoingcode = getSessionStorage('goingflightCode') || ''
  const storedflightReturnCode = getSessionStorage('returnflightCode') || ''
  const storedpassengerCount = getSessionStorage('passengerCounts') || 0
  const storeddepartTime = getSessionStorage('departTime') || ''
  const storedreturnTime = getSessionStorage('returnTime') || ''
  const storedrounddepartTime = getSessionStorage('rounddepartTime') || ''
  const storedroundreturnTime = getSessionStorage('roundreturnTime') || ''
  const storedroundreturndepartTime =
    getSessionStorage('roundreturndepartTime') || ''
  const storedroundreturnreturnTime =
    getSessionStorage('roundreturnreturnTime') || ''
  console.log(
    'dhsjfhdsf',
    dayjs(storedDate?.props?.children, 'DD-MM-YYYY').format(
      'YYYY/MM/DD')
  )

  useEffect(() => {
    getCountries()
    setBillingdetails((prevDetails) => ({
      ...prevDetails,
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      passengers: passengers,
    }))
  }, [selectedCountry, selectedState, selectedCity, passengers])

  async function getCountries() {
    try {
      const get_country = await getCountry()
      if (get_country !== 204 && get_country !== 401) {
        setCountries(get_country.data)
      }
    } catch (error) {
      console.log(error.message)
    }
  }

  async function getStates(id) {
    try {
      const res = await getState(id)
      if (res !== 204 && res !== 401) {
        setStates(res.data)
      }
    } catch (error) {
      console.log(error.message)
    }
  }
  async function getCities(id) {
    try {
      const get_city = await getCity(id)
      if (get_city !== 204 && get_city !== 401) {
        setCities(get_city.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleCountryChange = (event) => {
    const countryId = event.target.value
    setSelectedCountry(countryId)
    setSelectedState('') // Reset state selection
    // Fetch states for the selected country
    getStates(countryId)
  }

  const handleStateChange = (event) => {
    const stateId = event.target.value
    setSelectedState(stateId)
    // Fetch cities for the selected state
    getCities(stateId)
  }

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value)
  }

  const handleOpen = () => {
    setBooknow(true)
    console.log('hehehe', booknow)
  }
  // console.log(
  //   'totalpassengercount',
  //   totalpassengercount,
  //   storedpassengerCount,
  //   storedpassengerCount.adult,
  //   storedpassengerCount.child,
  //   storedpassengerCount.infant
  // )
  // const totalpassenger =
  //   storedAdultPassenger + storedChildPassenger + storedInfantPassenger
  // const totalpassengercount =
  //   storedpassengerCount.adult +
  //   storedpassengerCount.child +
  //   storedpassengerCount.infant
  const handleBooking = async () => {
    console.log('passengers', passengers)
    const totalpassenger =
      storedAdultPassenger + storedChildPassenger + storedInfantPassenger
    const totalpassengercount =
      storedpassengerCount.adult +
      storedpassengerCount.child +
      storedpassengerCount.infant
    if (totalpassengercount !== totalpassenger) {
      setOn(true)
      setSeverity('error')
      setMes('Please fill all Passengers details')
      return
    }

    const sendData = {
      origin: storedflightFrom,
      destination: storedflightTo,
      is_return_trip: storedTripType == 'one way' ? 0 : 1,
      departure_date:
        storedTripType == 'one way'
          ? dayjs(storedDate?.props?.children, 'DD-MM-YYYY').format(
              'YYYY/MM/DD'
            )
          : dayjs(storedflightReturnDD).format('YYYY/MM/DD'),
      return_date:
        storedTripType == 'one way'
          ? null
          : dayjs(storedflightReturnDA).format('YYYY/MM/DD'),
      total_adult: storedAdultPassenger,
      total_child: storedChildPassenger,
      total_infant: storedInfantPassenger,
      flight_code:
        storedTripType == 'one way' ? storedflightCode : storedflightgoingcode,
      return_flight_code:
        storedTripType == 'one way' ? null : storedflightReturnCode,
      class_type: 1,
      // transaction_id: dfdfd55fd5,  this will update after payment
      billing_name: billingdetails.name,
      billing_phone: billingdetails.phone_no,
      city: billingdetails.city,
      total_fare:
        storedTripType == 'one way'
          ? (storedAdultPassenger + storedChildPassenger) * storedPrice +
            storedInfantPassenger * 4500
          : (storedAdultPassenger + storedChildPassenger) *
              storedflightReturnDp +
            (storedAdultPassenger + storedChildPassenger) *
              storedflightReturnAp +
            storedInfantPassenger * 4500,
      state: billingdetails.state,
      pin_code: billingdetails.pincode,
      address: billingdetails.address,
      billing_email: billingdetails.email,
      passengers,
    }

    try {
      const res = await getPreLogindetails(sendData, setError)
      // const res = await handlePreloginpayment(sendData.total_fare, sendData.billing_phone, sendData.billing_name)
      console.log('Data successfully sent:', res)
      if (res !== 406) {
        setOpen(true)
        setBookingId(res.booking_id)
        setOn(true)
        setSeverity('success')
        setMes('Booking Done successfully')
      } else {
        setOn(true)
        setSeverity('error')
        setMes('Please fill all required fields')
      }
    } catch (error) {
      console.log(error)
    }
  }
  console.log(bookingId)

  const getSessionId = async () => {
    const sendData = {
      phone: billingdetails.phone_no,
      amount:
        storedTripType == 'one way'
          ? (storedAdultPassenger + storedChildPassenger) * storedPrice +
            storedInfantPassenger * 4500
          : (storedAdultPassenger + storedChildPassenger) *
              storedflightReturnDp +
            (storedAdultPassenger + storedChildPassenger) *
              storedflightReturnAp +
            storedInfantPassenger * 4500,
      name: billingdetails.name,
      email: billingdetails.email,
    }
    try {
      let res = await getPreLoginInitiatePayment(sendData)

      if (res) {
        return res
      }
    } catch (error) {
      console.log(error)
    }
  }
  const verifyPayment = async (
    order_id,
    bookingId,
    storedflightFromCode,
    storedflightToCode,
    name
  ) => {
    const sendData = {
      order_id: order_id,
      booking_id: bookingId,
      origin: storedflightFromCode,
      destination: storedflightToCode,
      name: name,
      departure_date:
        storedTripType == 'one way'
          ? dayjs(storedDate?.props?.children, 'DD-MM-YYYY').format(
              'YYYY/MM/DD'
            )
          : dayjs(storedflightReturnDD).format('YYYY/MM/DD'),
      return_date:
        storedTripType == 'one way'
          ? null
          : dayjs(storedflightReturnDA).format('YYYY/MM/DD'),
      // flight_code : storedTripType === 'one way'
      // ? [{ going_flight_code: storedflightCode, return_flight_code: null }]
      // : [{ going_flight_code: storedflightgoingcode, return_flight_code: storedflightReturnCode }],
      e_total_passenger: storedAdultPassenger + storedChildPassenger,
      going_flight_code:
        storedTripType === 'one way' ? storedflightCode : storedflightgoingcode,
      return_flight_code:
        storedTripType === 'one way' ? null : storedflightReturnCode,
      is_return_trip: storedTripType == 'one way' ? 0 : 1,
    }
    try {
      let res = await getPreLoginVerifyPayment(sendData)
      if (res.success == true) {
        console.log('success', res)
        setSessionStorage(res, 'res')
        navigate('/preloginpayment-success', { replace: true })
      }
    } catch (error) {
      console.log(error)
      // showAlert('Payment failed.', 'error')
      // setSpinner(false)
    }
  }
  const cashFreeRecharge = async () => {
    setOpen(false)
    try {
      const response = await getSessionId()
      let insitialzeSDK = async function () {
        cashfree = await load({
          mode: 'sandbox',
        })
      }

      insitialzeSDK()
      let checkoutOptions = {
        paymentSessionId: response?.data?.payment_session_id,
        redirectTarget: '_modal',
      }
      console.log(
        'response?.data?.payment_session_id',
        response?.data?.payment_session_id
      )
      cashfree.checkout(checkoutOptions).then((paymentStatus) => {
        // console.log(paymentStatus);
        if (paymentStatus.paymentDetails) {
          verifyPayment(
            response?.data?.order_id,
            bookingId,
            storedflightFromCode,
            storedflightToCode,
            billingdetails.name
          )
        } else if (paymentStatus.error) {
          verifyPayment(
            response?.data?.order_id,
            bookingId,
            storedflightFromCode,
            storedflightToCode,
            billingdetails.name
          )
          // showAlert('Payment Failed.', 'error')
          // setSpinner(false)
          // console.log("Payment failed. Not verifying.");
        }
        setCashFreeAmount('')
      })
    } catch (error) {
      console.log(error)
    }
  }
  console.log('error', error)

  return (
    <div>
      <Accordion sx={{ background: '#edf1f4' }} defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1-content'
          id='panel1-header'
          sx={{ fontWeight: 510, fontSize: '19px', color: '#01357E' }}
        >
          Flight Available
        </AccordionSummary>
        <AccordionDetails>
          <Card
            sx={{
              boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px',
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={2}>
                  <img
                    src='../images/Azerbaijan_Airlines.png'
                    alt='logo'
                    style={{ width: '70%' }}
                  />
                </Grid>
                <Grid item md={2}>
                  <Typography variant='h6'>3h 20m</Typography>
                  <Typography variant='body1'>Azerbaijan Airlines</Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography variant='h6'>
                    {' '}
                    {storedTripType === 'one way'
                      ? `${storeddepartTime} - ${storedreturnTime}`
                      : `${storedrounddepartTime} - ${storedroundreturnTime}`}
                  </Typography>
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '16px',
                    }}
                  >
                    <FlightTakeoff
                      sx={{ marginRight: '8px', color: '#01357E' }}
                    />{' '}
                    {/* Adjust margin as needed */}
                    {storedflightFromCode} - {storedflightToCode}
                    <FlightLand
                      sx={{ marginLeft: '8px', color: '#01357E' }}
                    />{' '}
                    {/* Adjust margin as needed */}
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant='h6'>
                    {dayjs(storedDate?.props?.children, 'DD-MM-YYYY').format(
                      'DD MMMM YYYY'
                    )}{' '}
                  </Typography>
                  <Typography variant='body1'>
                    {storedTripType} (Non-Stop)
                  </Typography>
                </Grid>
                <Grid item md={3} xs={12}>
                  <Card
                    sx={{
                      background: '#f2f5fb',
                      borderRadius: '12px',
                      padding: '16px',
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Grid item md={6}>
                        <Typography variant='body1' sx={{ color: '#01357E' }}>
                          Economy
                        </Typography>
                      </Grid>

                      <Grid item md={6}>
                        <Typography variant='h6' sx={{ color: '#01357E' }}>
                          {storedTripType == 'one way'
                            ? `${currencyFormatter.format(storedPrice)}`
                            : `${currencyFormatter.format(
                                storedflightReturnDp
                              )}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                {/* <Grid item md={2}>
                  <Button variant='contained' sx={{ background: '#01357E' }}>
                    Book Now
                  </Button>
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
          {storedTripType == 'Return Trip' && (
            <Card
              sx={{
                boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px',
              }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={2}>
                    <img
                      src='../images/Azerbaijan_Airlines.png'
                      alt='logo'
                      style={{ width: '70%' }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant='h6'>3h 20m</Typography>
                    <Typography variant='body1'>Azerbaijan Airlines</Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant='h6'>
                      {storedroundreturndepartTime} -{' '}
                      {storedroundreturnreturnTime}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '16px',
                      }}
                    >
                      <FlightTakeoff
                        sx={{ marginRight: '8px', color: '#01357E' }}
                      />{' '}
                      {/* Adjust margin as needed */}
                      {storedflightToCode} - {storedflightFromCode}
                      <FlightLand
                        sx={{ marginLeft: '8px', color: '#01357E' }}
                      />{' '}
                      {/* Adjust margin as needed */}
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography variant='h6'>{storedflightReturnDA}</Typography>
                    <Typography variant='body1'>(Non-Stop)</Typography>
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <Card
                      sx={{
                        background: '#f2f5fb',
                        borderRadius: '12px',
                        padding: '16px',
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Grid item md={6}>
                          <Typography variant='body1' sx={{ color: '#01357E' }}>
                            Economy
                          </Typography>
                        </Grid>

                        <Grid item md={6}>
                          {/* <Button
                        variant='contained'
                        sx={{ background: '#01357E' }}
                        onClick={handleOpen}
                      >
                        Book Now
                      </Button> */}
                          <Typography variant='h6' sx={{ color: '#01357E' }}>
                            {currencyFormatter.format(storedflightReturnAp)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  {/* <Grid item md={2}>
                <Button variant='contained' sx={{ background: '#01357E' }}>
                  Book Now
                </Button>
              </Grid> */}
                </Grid>
              </CardContent>
            </Card>
          )}
          <Button
            variant='contained'
            sx={{ background: '#01357E', float: 'right', margin: '10px' }}
            onClick={handleOpen}
          >
            Book Now
          </Button>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ background: '#edf1f4' }} expanded={booknow}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel2-content'
          id='panel2-header'
          sx={{ fontWeight: 510, fontSize: '19px', color: '#01357E' }}
        >
          Passenger information
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ fontSize: '15px', fontWeight: '500', marginBottom: '10px' }}
          >
            Enter the require information for each traveler and be sure that it
            exactly matches the goverment-issued ID presented at the airport.
          </Typography>
          <FormHelperText
            style={{ color: 'red', marginLeft: '5px', fontSize: '14px' }}
          >
            {error && error['passengers']}
          </FormHelperText>
          <Passenger_info
            setPassengerCounts={setPassengerCounts}
            setPassengers={setPassengers}
            passengers={passengers}
            adultPassenger={storedAdultPassenger}
            childPassenger={storedChildPassenger}
            infantPassenger={storedInfantPassenger}
          />
          {/* <PassengerDetails/> */}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ background: '#edf1f4' }} expanded={booknow}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel3-content'
          id='panel3-header'
          sx={{ fontWeight: 510, fontSize: '19px', color: '#01357E' }}
        >
          Payment Process
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ fontSize: '15px', fontWeight: 510, marginBottom: '10px' }}
          >
            Billing Details
          </Typography>
          <Card
            sx={{
              padding: '16px',
              boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px',
            }}
          >
            <Grid container spacing={2}>
              <Grid item md={2} xs={12}>
                <TextField
                  id='outlined-basic'
                  label='Name'
                  variant='outlined'
                  size='small'
                  name='name'
                  value={billingdetails.name}
                  onChange={(e) =>
                    handleChangeGlobal(
                      e,
                      setBillingdetails,
                      setError,
                      error,
                      'billing_name'
                    )
                  }
                  error={
                    (error && error['billing_name']) || error['billing_name']
                  }
                  fullWidth
                  required
                />
                <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
                  {error && error['billing_name']}
                </FormHelperText>
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  id='outlined-basic'
                  label='Email address'
                  variant='outlined'
                  size='small'
                  name='email'
                  value={billingdetails.email}
                  onChange={(e) =>
                    handleChangeGlobal(
                      e,
                      setBillingdetails,
                      setError,
                      error,
                      'billing_email'
                    )
                  }
                  error={
                    (error && error['billing_email']) || error['billing_email']
                  }
                  fullWidth
                  required
                />
                <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
                  {error && error['billing_email']}
                </FormHelperText>
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  id='outlined-basic'
                  label='Phone number'
                  variant='outlined'
                  size='small'
                  name='phone_no'
                  value={billingdetails.phone_no}
                  onChange={(e) => {
                    e.target.value.length < 11 &&
                      handleChangeGlobal(
                        e,
                        setBillingdetails,
                        setError,
                        error,
                        'billing_phone'
                      )
                  }}
                  error={
                    (error && error['billing_phone']) || error['billing_phone']
                  }
                  fullWidth
                  required
                />
                <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
                  {error && error['billing_phone']}
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={0}></Grid>
              <Grid item md={2} xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel>Country</InputLabel>
                  <Select
                    label='Counrty'
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={2} xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel>State</InputLabel>
                  <Select
                    label='State'
                    value={selectedState}
                    onChange={handleStateChange}
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.state_id}>
                        {state.state_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
                  {error && error['state']}
                </FormHelperText>
              </Grid>
              <Grid item md={2} xs={12}>
                <FormControl fullWidth size='small'>
                  <InputLabel>City</InputLabel>
                  <Select
                    label='City'
                    value={selectedCity}
                    onChange={handleCityChange}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.cityID} value={city.cityID}>
                        {city.cityName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
                  {error && error['city']}
                </FormHelperText>
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  id='outlined-basic'
                  label='Pincode'
                  variant='outlined'
                  size='small'
                  name='pincode'
                  value={billingdetails.pincode}
                  onChange={(e) =>
                    handleChangeGlobal(
                      e,
                      setBillingdetails,

                      setError,
                      error,
                      'pin_code'
                    )
                  }
                  error={(error && error['pin_code']) || error['pin_code']}
                  fullWidth
                  required
                />
                <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
                  {error && error['pin_code']}
                </FormHelperText>
              </Grid>
              <Grid item md={2} xs={12}>
                <TextField
                  id='outlined-basic'
                  label='Address'
                  variant='outlined'
                  size='small'
                  name='address'
                  value={billingdetails.address}
                  onChange={(e) =>
                    handleChangeGlobal(
                      e,
                      setBillingdetails,
                      setError,
                      error,
                      'address'
                    )
                  }
                  error={(error && error['address']) || error['address']}
                  fullWidth
                  required
                />
                <FormHelperText style={{ color: 'red', marginLeft: '5px' }}>
                  {error && error['address']}
                </FormHelperText>
              </Grid>
              <Grid item md={9} xs={12}>
                <Card
                  sx={{
                    boxShadow: ' rgba(0, 0, 0, 0.35) 0px 5px 15px',
                  }}
                >
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item md={3}>
                        <img
                          src='../images/Azerbaijan_Airlines.png'
                          alt='logo'
                          style={{ width: '70%' }}
                        />
                      </Grid>
                      <Grid item md={2.5}>
                        <Typography variant='h6'>
                          {storedTripType === 'one way'
                            ? `${storeddepartTime} - ${storedreturnTime}`
                            : `${storedrounddepartTime} - ${storedroundreturnTime}`}
                        </Typography>
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '16px',
                          }}
                        >
                          <FlightTakeoff
                            sx={{ marginRight: '8px', color: '#01357E' }}
                          />{' '}
                          ({storedflightFromCode}) - ({storedflightToCode})
                          <FlightLand
                            sx={{ marginLeft: '8px', color: '#01357E' }}
                          />{' '}
                        </Typography>
                      </Grid>
                      <Grid item md={2.5}>
                        {storedTripType == 'Return Trip' && (
                          <>
                            <Typography variant='h6'>
                              {storedroundreturndepartTime} -{' '}
                              {storedroundreturnreturnTime}
                            </Typography>
                            <Typography
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                fontSize: '16px',
                              }}
                            >
                              <FlightTakeoff
                                sx={{ marginRight: '8px', color: '#01357E' }}
                              />{' '}
                              {/* Adjust margin as needed */}(
                              {storedflightToCode}) - ({storedflightFromCode})
                              <FlightLand
                                sx={{ marginLeft: '8px', color: '#01357E' }}
                              />{' '}
                              {/* Adjust margin as needed */}
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {storedTripType === 'one way' ? (
                          <Typography variant='h6'>
                            {dayjs(
                              storedDate?.props?.children,
                              'DD-MM-YYYY'
                            ).format('DD MMMM YYYY')}{' '}
                            (Non-Stop)
                          </Typography>
                        ) : (
                          <Typography variant='h6'>
                            {storedflightReturnDD} - {storedflightReturnDA}
                          </Typography>
                        )}
                        <Typography variant='body1'>
                          {storedTripType}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={3} xs={12}>
                <Card
                  sx={{
                    background: '#f2f5fb',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Grid item md={6}>
                      <Typography
                        sx={{
                          fontSize: '17px',
                          fontWeight: 510,

                          color: '#01357E',
                        }}
                      >
                        Subtotal :-
                      </Typography>
                    </Grid>

                    <Grid item md={6}>
                      {/* <Button
                        variant='contained'
                        sx={{ background: '#01357E' }}
                        onClick={handleOpen}
                      >
                        Book Now
                      </Button> */}
                      <Typography
                        sx={{
                          fontSize: '17px',
                          fontWeight: 500,
                          color: '#01357E',
                        }}
                      >
                        {storedTripType == 'one way'
                          ? `${currencyFormatter.format(
                              (storedAdultPassenger + storedChildPassenger) *
                                storedPrice +
                                storedInfantPassenger * 4500
                            )}`
                          : `${currencyFormatter.format(
                              (storedAdultPassenger + storedChildPassenger) *
                                storedflightReturnDp +
                                (storedAdultPassenger + storedChildPassenger) *
                                  storedflightReturnAp +
                                storedInfantPassenger * 4500
                            )}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    margin: '5px',
                    color: '#01357E',
                  }}
                >
                  Including all taxes *
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </AccordionDetails>
        <AccordionActions>
          <div>
            <Button
              variant='contained'
              sx={{ background: '#01357E', marginBottom: '20px' }}
              onClick={handleBooking}
            >
              Confirm and pay
            </Button>
            <Modal
              open={open}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Card sx={style}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <Typography
                      id='modal-modal-title'
                      sx={{ fontSize: '19px', fontWeight: 510 }}
                    >
                      Charges list Disclaimer
                    </Typography>
                    <Typography
                      id='modal-modal-description'
                      sx={{ fontSize: '15px', color: '#A9A9A9' }}
                    >
                      Details of charges and penalties.
                    </Typography>
                  </div>
                  <IconButton aria-label='delete' onClick={handleClose}>
                    <Cancel />
                  </IconButton>
                </div>

                <CardContent
                  sx={{ background: '#edf1f4', borderRadius: 2, mt: 1 }}
                >
                  <Typography
                    id='modal-modal-description'
                    sx={{ color: '#01357E', fontWeight: 500, fontSize: '15px' }}
                  >
                    1. Canceled ticket charges - 60 Euro per sector, both
                    sectors- 120 Euro
                  </Typography>
                  <Typography
                    id='modal-modal-description'
                    sx={{
                      mt: 1,
                      color: '#01357E',
                      fontWeight: 500,
                      fontSize: '15px',
                    }}
                  >
                    2. Name change penalty - 60 Euro/12500 in INR
                  </Typography>
                  <Typography
                    id='modal-modal-description'
                    sx={{
                      mt: 1,
                      color: '#01357E',
                      fontWeight: 500,
                      fontSize: '15px',
                    }}
                  >
                    3. Date change penalty - 60 Euro per sector, both sectors-
                    120 Euro
                  </Typography>
                </CardContent>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'cenetr',
                    float: 'right',
                    marginTop: '10px',
                    gap: '10px',
                  }}
                >
                  <Button
                    variant='contained'
                    sx={{ background: '#01357E' }}
                    onClick={cashFreeRecharge}
                  >
                    Proceed to pay
                  </Button>
                </div>
              </Card>
            </Modal>
          </div>
        </AccordionActions>
      </Accordion>
      <div style={{ display: 'flex' }}>
        <img src='../images/hello.png' alt='logo' style={{ width: '100%' }} />
      </div>
    </div>
  )
}

export default Accordioncard
