import React, { useEffect, useState } from 'react'
import 'react-day-picker/dist/style.css'
import { format } from 'date-fns'
import { DayPicker } from 'react-day-picker'
import dayjs from 'dayjs'
import { getFlightListApi } from '../../services/admin.configService'
import { setSessionStorage } from '../../utils/common'
import { useMediaQuery, useTheme } from '@mui/material'
import { WEEKS } from '../../utils/constant'

const date = new Date()
date.toLocaleDateString()

export default function App({ x, flightFromCode, flightToCode }) {
  const theme = useTheme()
  const [days, setDays] = React.useState()
  const [departDate, setDepartDate] = React.useState('')
  const [returnDate, setReturnDate] = React.useState('')
  const [flightList, setFlightList] = React.useState([])
  const [goingPrice, setGoingPrice] = React.useState('')
  const [returnPrice, setreturnPrice] = React.useState('')
  const [goingflightCode, setGoingflightCode] = React.useState('')
  const [returnflightCode, setReturnFlightCode] = React.useState('')
  const [rounddepartTime, setRounddepartTime] = React.useState('')
  const [roundreturnTime, setRoundreturnTime] = React.useState('')
  const [roundreturndepartTime, setRoundreturndepartTime] = React.useState('')
  const [roundreturnreturnTime, setRoundreturnreturnTime] = React.useState('')

  console.log('helloo', departDate, returnDate)

  const Setdays = (e) => {
    setDays(e)
    const w = e && e.length > 0 && e[0]
    const y = e && e.length > 0 && e[1]
    setDepartDate(dayjs(w).format('DD MMMM YYYY'))
    setReturnDate(dayjs(y).format('DD MMMM YYYY'))

    const dateObj = dayjs(departDate, 'D MMM YYYY')
    const day = dateObj.format('ddd')
    const dateId = WEEKS.find((item) => item.label === day)?.id
    console.log('@#@#@$#@$@#$$#$@#', dateObj, day, dateId)
    const dt = flightList
      .filter(
        (item) =>
          item.flight_departure_from == flightFromCode &&
          item.flight_departure_to == flightToCode
      )[0]
      ?.day_time.find(
        (item) => item.flight_day === dateId
      )?.flight_departure_time
    setRounddepartTime(dt?.slice(0, 5))

    const at = flightList
      .filter(
        (item) =>
          item.flight_departure_from == flightFromCode &&
          item.flight_departure_to == flightToCode
      )[0]
      ?.day_time.find((item) => item.flight_day === dateId)?.flight_arrival_time
    setRoundreturnTime(at?.slice(0, 5))

    const rddateObj = dayjs(returnDate, 'D MMM YYYY')
    const rdday = rddateObj.format('ddd')
    const rddateId = WEEKS.find((item) => item.label === rdday)?.id

    const returnDt = flightList
      .filter(
        (item) =>
          item.flight_departure_from == flightToCode &&
          item.flight_departure_to == flightFromCode
      )[0]
      ?.day_time.find(
        (item) => item.flight_day === rddateId
      )?.flight_departure_time
    setRoundreturndepartTime(returnDt?.slice(0, 5))

    const returnAt = flightList
      .filter(
        (item) =>
          item.flight_departure_from == flightToCode &&
          item.flight_departure_to == flightFromCode
      )[0]
      ?.day_time.find(
        (item) => item.flight_day === rddateId
      )?.flight_arrival_time
    setRoundreturnreturnTime(returnAt?.slice(0, 5))

    const a = flightList.filter(
      (item) =>
        item.flight_departure_from == flightFromCode &&
        item.flight_departure_to == flightToCode
    )[0]?.flight_available

    const b = flightList.filter(
      (item) =>
        item.flight_departure_from == flightToCode &&
        item.flight_departure_to == flightFromCode
    )[0]?.flight_available

    console.log('a', a, 'b', b)

    const particularGoing = a?.find(
      (item) => item?.date == dayjs(w).format('DD-MM-YYYY')
    )?.price
    setGoingPrice(particularGoing)
    const priceReturn = b?.find(
      (item) => item?.date == dayjs(y).format('DD-MM-YYYY')
    )?.price
    setreturnPrice(priceReturn)

    const goingCode = a?.find(
      (item) => item?.date == dayjs(w).format('DD-MM-YYYY')
    )?.code
    const flightCode = goingCode ? goingCode.substring(10) : null
    setGoingflightCode(flightCode)

    const returnCode = b?.find(
      (item) => item?.date == dayjs(y).format('DD-MM-YYYY')
    )?.code
    console.log(
      dayjs(w).format('DD-MM-YYYY'),
      dayjs(y).format('DD-MM-YYYY'),
      'returncode',
      returnCode,
      goingCode
    )
    const returnflightCode = returnCode ? returnCode.substring(10) : null
    setReturnFlightCode(returnflightCode)

    e && e.length > 1
      ? x(
          <strong>
            {`${format(w, 'dd-MM-yyyy')} - ${format(y, 'dd-MM-yyyy')}`}
          </strong>
        )
      : x(<strong>Pick days</strong>)
  }
  const disableDateFrom = (calDate) => {
    const x = flightList.filter(
      (item) =>
        item.flight_departure_from == flightFromCode &&
        item.flight_departure_to == flightToCode
    )[0]?.flight_available

    const arr = []

    x?.forEach((item) => {
      if (item.is_available) {
        return arr.push(item.date)
      }
    })
    var z = dayjs(calDate).format('DD-MM-YYYY')

    return !arr?.includes(z)
  }

  const disableDateTo = (calDate) => {
    const x = flightList.filter(
      (item) =>
        item.flight_departure_from == flightToCode &&
        item.flight_departure_to == flightFromCode
    )[0]?.flight_available

    const arr = []

    x?.forEach((item) => {
      if (item.is_available) {
        return arr.push(item.date)
      }
    })
    var z = dayjs(calDate).format('DD-MM-YYYY')

    return !arr?.includes(z)
  }

  const getFlightList = async () => {
    try {
      const res = await getFlightListApi()
      if (res !== 204 && res !== 401) {
        setFlightList(res?.data)
      }
    } catch (error) {
      alert(error.message)
    }
  }
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  useEffect(() => {
    getFlightList()
    // disableDateFrom()
    // disableDateTo()
    setSessionStorage(departDate, 'departDate')
    setSessionStorage(returnDate, 'returnDate')
    setSessionStorage(goingPrice, 'goingPrice')
    setSessionStorage(returnPrice, 'returnPrice')
    setSessionStorage(goingflightCode, 'goingflightCode')
    setSessionStorage(returnflightCode, 'returnflightCode')
    setSessionStorage(rounddepartTime || '', 'rounddepartTime')
    setSessionStorage(roundreturnTime || '', 'roundreturnTime')
    setSessionStorage(roundreturndepartTime || '', 'roundreturndepartTime')
    setSessionStorage(roundreturnreturnTime || '', 'roundreturnreturnTime')
  }, [
    flightFromCode,
    departDate,
    returnDate,
    returnPrice,
    goingPrice,
    goingflightCode,
    returnflightCode,
  ])

  return (
    <DayPicker
      mode='multiple'
      min={0}
      max={2}
      numberOfMonths={isSmallScreen ? 1 : 2}
      selected={days}
      onSelect={Setdays}
      disabled={days?.length > 0 ? disableDateTo : disableDateFrom}
      hidden={{ before: new Date() }}
      fromDate={new Date()}
    />
  )
}
