import * as React from 'react'
import dayjs from 'dayjs'
import Badge from '@mui/material/Badge'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Card,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  IconButton,
  Button,
} from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { TextField } from '@mui/material'
import {
  AirplanemodeInactive,
  ArrowBack,
  ArrowCircleLeft,
  BackHand,
  CheckBox,
  CheckBoxOutlineBlank,
  EditTwoTone,
} from '@mui/icons-material'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { getFlightListApi } from '../../services/admin.configService'
import { availableStatus } from '../../services/configService'
import DashboardLayout from '../dashboard_layout/DashboardLayout'
import { SnackbarContext } from '../../context/Snackbar'
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 0.5,
};

function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min)
}

function fakeFetch(date, { signal }) {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth()
      const daysToHighlight = [1, 2, 3].map(() =>
        getRandomNumber(1, daysInMonth)
      )

      resolve({ daysToHighlight })
    }, 500)

    signal.onabort = () => {
      clearTimeout(timeout)
      reject(new DOMException('aborted', 'AbortError'))
    }
  })
}
const date = new Date()
date.toLocaleDateString()
const initialValue = dayjs(date)

export default function DateCalendarServerRequest() {
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)

  const requestAbortController = React.useRef(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [highlightedDays, setHighlightedDays] = React.useState([1, 2, 15])
  const [flightList, setFlightList] = React.useState([])
  const [flightavailable, setFlightavailable] = React.useState([])
  const [flightFromCode, setFlightFromCode] = React.useState('DEL')
  const [selectedFlightCode, setSelectedFlightCode] = React.useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isChecked, setIsChecked] = React.useState(false)
  const [editdate, setEditdate] = React.useState('')
  const [flight, setFlight] = React.useState({
    available: 1,
    price: 17000,
  })

  const handleChange = (event) => {
    setFlight({
      ...flight, // spread existing flight state
      available: event.target.value, // update only the price property
    })
  }

  const handlePriceChange = (event) => {
    // Update the flight state with the new price value
    setFlight({
      ...flight, // spread existing flight state
      price: event.target.value, // update only the price property
    })
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const id = open ? editdate : undefined
 

  const handleSave = async () => {
    setAnchorEl(null)

    const sendData = {
      is_available: flight.available,
      price: flight.price,
    }

    try {
      const res = await availableStatus(editdate, sendData)
      if (res !== 204 && res !== 401) {
        setSeverity('success')
        setMes('Updated Successfully')
        setOn(true)
        getFlightList()
      }
    } catch (error) {
      console.error('Error sending data:', error)
    }
  }

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController()
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight)
        setIsLoading(false)
      })
      .catch((error) => {
       
        if (error.name !== 'AbortError') {
          throw error
        }
      })

    requestAbortController.current = controller
  }

  const getFlightList = async () => {
    try {
      const res = await getFlightListApi()
      if (res !== 204 && res !== 401) {
        setFlightList(res?.data)
      }
    } catch (error) {
      alert(error.message)
    }
  }



  const disableDateFrom = (calDate) => {
    // let days = flightList
    //   .filter((item) => item.flight_code == selectedFlightCode)[0]
    //   ?.flight_days.split(',')
    //   .map(Number)
    let days = flightList
    .filter((item) => item.flight_code == selectedFlightCode)[0]
    ?.day_time.map((item)=> item.flight_day)
    
    setFlightavailable(
      flightList.filter(
        (item) =>
          item.flight_departure_from == flightFromCode &&
          item.flight_code == selectedFlightCode
      )[0]?.flight_available
    )

    const flatDays = days?.flat(1)
    const currentDay = new Date(calDate).getDay()

    return !flatDays?.includes(currentDay)
  }

  function ServerDay(props) {
    const {
      highlightedDays = [],
      day,
      minDate,
      outsideCurrentMonth,
      ...other
    } = props

    const handleClick = (event) => {
      console.log(event)
      setAnchorEl(event.currentTarget)
      const dateKey = dayjs(day).format('YYYY-MM-DD') + selectedFlightCode
      setEditdate(dateKey)
      console.log('datekey', dateKey)
    }
    console.log('hello', editdate)

    const selectmindate = dayjs(minDate)

    const handleCheckboxChange = () => {
      const dateKey = dayjs(day).format('YYYY-MM-DD') + selectedFlightCode
      availableStatus(dateKey) 
    }

    const isSelected = disableDateFrom(day)
    const selectOutsideCurrentMonth = outsideCurrentMonth ? true : false

    const isOutsideCurrentMonth = outsideCurrentMonth
      ? '1px solid #ccc !important'
      : 'none'

    return (
      <Badge
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        key={props.day.toString()}
        overlap='circular'
        badgeContent={
          !isSelected &&
          !selectOutsideCurrentMonth &&
          dayjs(day).isAfter(selectmindate) ? (
            <>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '55px',
                  marginTop: '3px',
                }}
              >
                <Checkbox
                  size='medium'
                  // value={dayjs(day).format('YYYY-MM-DD')}
                  // onClick={(e)}
                  checked={
                    flightavailable?.find(
                      (item) =>
                        item.code ==
                        dayjs(day).format('YYYY-MM-DD') + selectedFlightCode
                    )?.is_available
                      ? true
                      : false
                  }
                  // checked={isChecked}
                  disabled
                  // onClick={handleCheckboxChange}
                  // color={isChecked ? 'error' : 'success'}
                />
                <div>
                  <IconButton
                    sx={{ paddingTop: 2 }}
                    color='info'
                    size='large'
                    aria-describedby={id}
                    onClick={handleClick}

                    // onClick={() => handleEdit(passenger.id)}
                  >
                    <EditTwoTone />
                  </IconButton>
                </div>
              </div>
            </>
          ) : undefined
        }
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          onClick={(e, v) => console.log({ ...e.target })}
          className={`abc ${outsideCurrentMonth && 'disabled'}`}
          style={{
            padding: '35px', // Adjust day width as needed
            fontSize: '18px', // Adjust font size as needed
            borderRadius: 0,
            // border: outsideCurrentMonth &&  '1px solid #ccc', // Apply grey border for days outside current month
            background: `${isChecked ? 'rgb(255 172 176)' : ''}`,
          }}
        />
      </Badge>
    )
  }

  React.useEffect(() => {
    fetchHighlightedDays(initialValue)
    getFlightList()
    // abort request on unmount
    return () => requestAbortController.current?.abort()
  }, [])

  const handleMonthChange = (date) => {
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort()
    }

    setIsLoading(true)
    setHighlightedDays([])
    fetchHighlightedDays(date)
  }

  return (
    <DashboardLayout
      heading='Set Seat Availability'
      pathName='Set Seat Availability'
    >
      <Grid container spacing={2}>
        <Grid item md={3} xs={3} mt={4}>
          <FormControl sx={{ marginLeft: '20px' }} fullWidth size='small'>
            <InputLabel id='demo-simple-select-label'>Select Origin</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              className='form_style'
              value={flightFromCode}
              label='Select Origin'
              onChange={(e) => {
                setFlightFromCode(e.target.value)
              }}
            >
              <MenuItem value={'DEL'}>Delhi</MenuItem>
              <MenuItem value={'BOM'}>Mumbai</MenuItem>
              <MenuItem value={'GYD'}>Baku</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            style={{ marginTop: '20px', marginLeft: '20px' }}
            size='small'
          >
            <InputLabel id='flight-code-label'>Select Flight Code</InputLabel>
            <Select
              labelId='flight-code-label'
              id='demo-simple-select'
              className='form_style'
              value={selectedFlightCode}
              label='Select Flight Code'
              onChange={(e) => setSelectedFlightCode(e.target.value)}
            >
              {flightList
                .filter((item) => item.flight_departure_from === flightFromCode)
                .map((item) => (
                  <MenuItem
                    key={item.flight_departure_from}
                    value={item.flight_code}
                  >
                    {item.flight_code} ({item.flight_departure_to})
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <div style={{ display: 'flex', gap: '5px', marginLeft: '20px' }}>
            <Chip
              label='Seat Available'
              variant='outlined'
              // color='success'
              icon={<CheckBox fontSize='small' />}
              sx={{ marginTop: '20px' }}
            />
            <Chip
              label='Seat Not Available'
              variant='outlined'
              sx={{ marginTop: '20px' }}
              icon={<CheckBoxOutlineBlank fontSize='small' />}
            />
          </div>
        </Grid>
        <Grid item md={1} xs={1}></Grid>
        <Grid item md={8} xs={8} sx={{ background: 'white' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              showDaysOutsideCurrentMonth
              defaultValue={initialValue}
              loading={isLoading}
              onMonthChange={handleMonthChange}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{
                day: ServerDay,
              }}
              slotProps={{
                day: {
                  highlightedDays,

                  style: {
                    width: '90px', // Adjust day label width as needed
                    fontSize: '14px', // Adjust day label font size as needed
                  },
                },
              }}
              shouldDisableDate={(date) => disableDateFrom(date)}
              minDate={dayjs(date)}
              className='customday'
              sx={{
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                paddingBottom: '30px',
                '& .MuiDayCalendar-header': {
                  gap: '37px', // Add a gap of 60px to MuiDayCalendar-header
                },
              }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
        <Card sx={style}>
          <CardContent sx={{ paddingBottom: '5px', paddingTop: '5px' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <IconButton aria-label='delete' onClick={handleClose}>
                  <ArrowCircleLeft />
                </IconButton>
                <Typography sx={{ fontWeight: 510, fontSize: '16px' }}>
                  Flight Price & Seat Available
                </Typography>
              </div>
              <div>
                {/* <IconButton aria-label='delete'>
                <ArrowCircleLeft />
              </IconButton> */}
                <Button variant='outlined' size='small' onClick={handleSave}>
                  save
                </Button>
              </div>
            </div>
          </CardContent>
          <CardContent sx={{ background: '#dce3ea' }}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={6}>
                <TextField
                  type='number'
                  value={flight.price}
                  onChange={handlePriceChange}
                  variant='outlined'
                  fullWidth
                  size='small'
                  label='Flight Price'
                />
              </Grid>
              <Grid item md={6} xs={6}>
                <FormControl fullWidth size='small'>
                  <InputLabel id='demo-simple-select-label'>
                    Seat Availability
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={flight.available}
                    label='Seat Availability'
                    onChange={handleChange}
                  >
                    <MenuItem value={0}>Not available </MenuItem>
                    <MenuItem value={1}>Available </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        </Modal>
    </DashboardLayout>
  )
}
