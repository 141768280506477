// export const baseURL = "http://localhost:8080/api/v1";
const production = 'https://api.zeal-azal.com/api/v1'
const dev = 'https://api.ticketing.sgate.net/api/v1'

export const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8080/api/v1'
    : 'https://api.zeal-azal.com/api/v1'


export const GET = (path) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then(async (res) => {
        if (res.status === 200) {
          resolve(res.json())
        } else if (res.status === 204) {
          resolve(204)
          reject(new Error('Content not found'))
        } else if (res.status === 401) {
          resolve(401)
        } else if (res.status === 409) {
          reject(new Error('Already Exists, Duplicate entries found'))
        }
      })
      .catch((error) => reject(error))
  })
}

export const POST = (path, data, setError) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        if (res.status === 200) {
          resolve(res.json())
        } else if (res.status === 406) {
          const data = await res.json()
          setError(
            data.errors.reduce((acc, cur) => {
              acc[cur.path] = cur.msg
              return acc
            }, {})
          )
          resolve(406)
        } else if (res.status === 400) {
          reject(new Error('Server Error'))
        } else if (res.status === 409) {
          reject(new Error('ALready Exists, Duplicate entries found'))
        }
      })
      .catch((error) => reject(error))
  })
}

export const PUT = (path, data, setError) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + path, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        if (res.status === 200) {
          resolve(res.json())
        } else if (res.status === 406) {
          const data = await res.json()
          setError(
            data.errors.reduce((acc, cur) => {
              acc[cur.path] = cur.msg
              return acc
            }, {})
          )
          resolve(406)
        } else if (res.status === 204) {
          resolve(204)
        }
      })
      .catch((error) => reject(error))
  })
}

export const AuthPOST = async (path, data, setError) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(data),
    })
      .then(async (res) => {
        if (res.status === 200) {
          resolve(res.json())
        } else if (res.status === 406) {
          const data = await res.json()
          setError(
            data.errors.reduce((acc, cur) => {
              acc[cur.path] = cur.msg
              return acc
            }, {})
          )
          resolve(406)
          // reject(new Error("Please enter valid Details"));
        } else if (res.status === 204) {
          reject(new Error('Data not found'))
        } else if (res.status === 400) {
          reject(new Error('Server Error'))
        } else if (res.status === 401) {
          reject(new Error('Please enter valid credentials'))
        } else if (res.status === 410) {
          // resolve(410)
          reject(new Error('OTP expired, Please resend !'))
        } else {
          reject(new Error('Unexpected error'))
        }
      })
      .catch((error) => reject(error))
  })
}

export const DELETE = (path) => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + path, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then(async (res) => {
        if (res.status === 200) {
          resolve(200)
        }
      })
      .catch((error) => console.log('45', error))
  })
}

export const FORMDATA = async (path, data, setError, method = 'POST') => {
  return new Promise((resolve, reject) => {
    fetch(baseURL + path, {
      credentials: 'include',
      body: data,
      method: method,
    })
      .then(async (res) => {
        if (res.status === 200) {
          resolve(res.json())
        } else if (res.status === 406) {
          const data = await res.json()
          setError(
            data.errors.reduce((acc, cur) => {
              acc[cur.path] = cur.msg
              return acc
            }, {})
          )
          resolve(406)
        } else if (res.status === 204) {
          reject(new Error('User not found'))
        } else if (res.status === 400) {
          reject(new Error('Server Error'))
        } else if (res.status === 401) {
          reject(new Error('Credential error'))
        } else if (res.status === 410) {
          // resolve(410)
          reject(new Error('OTP expired, Please resend !'))
        } else {
          reject(new Error('Unexpected error'))
        }
      })
      .catch((error) => reject(error))
  })
}
