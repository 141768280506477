import { Close } from '@mui/icons-material'
import { Button, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import LoadingBtn from '../common/LoadingBtn'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 470,
  bgcolor: 'background.paper',
  borderTop: '6px solid #de4e4e',
  boxShadow: 24,
  borderRadius: '1px 1px 8px 8px',
}

const Deletecard = ({onClickBack,onClickSubmit,btnText,subheading,isLoading}) => {
  return (
    <Card sx={style}>
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '26px',
          marginTop: '6px',
        }}
      >
        <div
          style={{
            height: '65px',
            width: '65px',
            borderRadius: '50%',
            border: '3px solid tomato',
            display :"flex",
            justifyContent :"center",
            alignItems : "center"
          }}
        >
         <Close color='warning' fontSize='large'/>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Typography variant='h6' color='GrayText'>
            Are you sure?
          </Typography>

          <Typography
            variant='body2'
            color='GrayText'
            marginTop={2}
            marginBottom={1}
          >
           Do you really want to <strong>{subheading }</strong>. This process cannot be undone.
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <Button variant='contained' color='back' sx={{ width: '100px' }} onClick={onClickBack}>
            Back
          </Button>
          <LoadingBtn variant='contained' color='error' loading={isLoading} style={{ width: '100px' }} onClick={onClickSubmit} text={btnText}/>
        </div>
      </CardContent>
    </Card>
  )
}

export default Deletecard
