import { CloudDownload, Home } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import React, {  useState } from "react";
import { useCSVDownloader } from "react-papaparse";
import { useCSVReader } from "react-papaparse";
import { NavLink, useNavigate } from "react-router-dom";
import { addPassengersCSVApi } from "../../../../services/configService";

const PassengerHeader = ({  query_id, setError }) => {
  const navigate = useNavigate();
  const { CSVReader } = useCSVReader();
  const { CSVDownloader, Type } = useCSVDownloader();
  // const [col, setCol] = useState([]);
  // const [csvData, setCsvData] = useState([]);
  // const [val, setVal] = useState([]);

  const sendColumn = [
    "title",
    "first_name",
    "last_name",
    "mobile_no",
    "mobile_code",
    "email_id",
    "passport_no",
    "pan_no",
    "dob",
    "passport_expiry_date",
  ];
  
  async function uploadCsvData(csv) {
      const sendJson = csv?.slice(1)?.map((item) => {
        const obj = {};
        sendColumn.forEach((key, index) => {
          obj[key] = item[index];
        });
        return obj;
      });
    const data = {
      csvData: sendJson,
      query_id,
    };
    console.log("send",data)
    try {
      const res = await addPassengersCSVApi(data, setError);
      console.log("res ", res);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }



  const csvDataColumn = [
    {
      "title_(Mr,Mrs,Ms)": "Mrs",
      first_name: "",
      last_name: "",
      mobile_no: "",
      mobile_code: +91,
      email_id: "",
      passport_no: "",
      pan_no: "",
      "Dob_(YYYY-MM-DD)": "",
      "passport_expiry_date_(YYYY-MM-DD)": "",
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 10px 0px 10px",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}
        >
          <div>
            <Typography
              fontWeight={500}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "15px",
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/dashboard")}
              >
                <Home fontSize="small" sx={{ marginTop: "2px" }} />
              </span>{" "}
              &nbsp; / &nbsp;
              <NavLink to={"/booking"}>
                <span style={{ color: "black" }}>{"Booking"} </span>
              </NavLink>
              <span style={{ color: "grey" }}>
                &nbsp; &nbsp;/ &nbsp;{"Add Passenger"}
              </span>
            </Typography>
            <Typography
              variant="h6"
              textAlign="left"
              sx={{ fontWeight: "500", fontSize: "22px", marginTop: "0px" }}
            >
              Add Passenger
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex", gap: "15px", marginRight: "10px" }}>
          <CSVReader
            onUploadAccepted={(results) => {
              // setCsvData(results.data);
              // const value = results.data;
              // const filtered = value.filter((_, i) => i !== 0);
              // setCol(value[0]);
              // setVal(filtered);
              uploadCsvData(results.data)
            }}
          >
            {({ getRootProps}) => (
              <>
                <div>
                  <Button
                    variant="container"
                    sx={{ backgroundColor: "black", color: "white", '&:hover' : {backgroundColor :"black"} }}
                    type="button"
                    size="small"
                    {...getRootProps()}
                  >
                    Upload file
                  </Button>
                </div>
              </>
            )}
          </CSVReader>

          <CSVDownloader
            type={Type.Button}
            filename={"passenger-template"}
            bom={true}
            delimiter={","}
            data={csvDataColumn}
            style={{
              backgroundColor: "black",
              color: "white",
              border: "none",
              display: "flex",
              alignItems: "center",
              padding: "6px 10px",
              cursor: "pointer",
              borderRadius: "3px",
              height: "37px",
            }}
          >
            <CloudDownload /> &nbsp; Dummy CSV
          </CSVDownloader>
        </div>
      </div>
    </>
  );
};

export default PassengerHeader;
