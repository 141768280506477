/* eslint-disable */
// prettier-ignore
import React, { useCallback, useContext, useEffect, useState } from 'react'
import  {useNavigate,useLocation, useSearchParams} from 'react-router-dom'
import DashboardLayout from '../../../components/dashboard_layout/DashboardLayout'
import { Grid,FormControl,Typography,  InputLabel,MenuItem, Select,TableCell,TableHead,TableContainer,Table,TableRow,TableBody,TextField,FormHelperText } from '@mui/material'
import { getSessionStorage } from '../../../utils/common'
import { Add, AddCircleOutline, Circle } from '@mui/icons-material'
import { createCrnApi, getCreditnoteApi } from '../../../services/admin.configService'
import { SnackbarContext } from '../../../context/Snackbar'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { getAddressListApi } from '../../../services/configService';

const page = {
  "update-invoice" : "update",
  "create-crn" :  'crn'
}

const CreateCRN = () => {
  
  // const [searchParams,setSearchParams] = useSearchParams()
  // const name = searchParams.get('page');
  const {pathname} = useLocation();
  const name = page[pathname.split('/')[2]]
  const data = getSessionStorage(name);
  
  const { setOn, setSeverity, setMes } = useContext(SnackbarContext);
  const navigate = useNavigate()
  const [rows, setRows] = useState([]);
  const [inputAmount,setinputAmount] = useState("");
  const [modeofpayement,setModeofpayement] = useState("");
  const [error,setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceDate, setInvoiceDate] = useState(data?.invoice_date)
  const [addressList, setAddressList] = useState([])

  async function getCreditNote(){
    try {
        const res = await getCreditnoteApi(data?.inv_id)
        if(res !== 204 && res !== 401) {
            console.log("res",res.data)
            setRows(res.data)
        }
    } catch (error) {
        console.log(error)
    }
  }

  const handleModeofpayement = (e) => {
    const value = e.target.value;
    // console.log(value , "back",rows[0]?.available_amount)
    // if(false){  // got backend data null // need check
    //     setSeverity("error");
    //     setMes("Please enter amount less than total amount");
    //     setOn(true);
    // }
    // else{
    //     setinputAmount(value)
    // }
    setModeofpayement(value)
  }
  const invoicedata = getSessionStorage('invoicedata')
  console.log("modeofpayement",invoicedata);

  const handleChangeAmount = (e) => {
    const value = e.target.value;
    console.log(value , "back",rows[0]?.available_amount)
    if(false){  // got backend data null // need check
        setSeverity("error");
        setMes("Please enter amount less than total amount");
        setOn(true);
    }
    else{
        setinputAmount(value)
    }
  }

  const handleCreateCRN = async() => {
    const sendData = {
        total_amount : inputAmount,
        inv_id : data?.inv_id,
        mode_payement : modeofpayement,
        is_prelogin: invoicedata.is_prelogin_booking
    }
    try {
        setIsLoading(true)
        const res = await createCrnApi(data?.query_id,sendData,setError);
        if(res !== 406 && res!== 401){
            setSeverity("success");
            setMes("CRN created successfully");
            setOn(true);
            navigate('/invoices')
        }
    } catch (error) {
        setSeverity("warning");
        setMes(error.message);
        setOn(true);
    }
    finally{
        setIsLoading(false)
    }
  }


//   update invoice function

  const getAddressList = useCallback(async () => {
    try {
      const res = await getAddressListApi()
      if (res !== 204 && res !== 401) {
        setAddressList(res.data)
      }
    } catch (error) {
      console.log(error.message)
    }
  }, [])

  useEffect(()=>{
    getCreditNote()
  },[])


  return (
    <DashboardLayout
        pathName1={name === 'crn' ? "Create CRN"  : "Update Invoice"}
        pathName={"Invoices"}
        pathLink={"/invoices"}
        heading={name === 'crn' ? "Create CRN"  : "Update Invoice"}
        button={true}
        btnIcon={<AddCircleOutline/>}
        btnText={"Create"}
        onClick={handleCreateCRN}
        isLoading={isLoading}
    >
        <Grid container bgcolor={'#479f2d6e'} color={'#0c310c'} height={'100%'} p={2}>
            <Grid item  md={4} xs={12} display={"flex"}>
                <Typography variant="inherit" color="" width={110}  >Invoice : </Typography>
                <Typography variant="subtitle2" color="">&nbsp; {data?.invoice_no} </Typography>
            </Grid>
            <Grid item md={4} xs={12} display={"flex"}>
                <Typography variant="inherit" color="" width={80} >Query : </Typography>
                <Typography variant="subtitle2" color="">&nbsp; {data?.query_code} </Typography>
            </Grid>
            <Grid item md={4} xs={12} display={"flex"}>
                <Typography variant="inherit" color="" width={65} >Agent : </Typography>
                <Typography variant="subtitle2" color="">&nbsp; {(data?.agent)?.toUpperCase()} </Typography>
            </Grid>
            <Grid item md={4} xs={12} display={"flex"}>
                <Typography variant="inherit" color="" width={110}  >Agent Type : </Typography>
                <Typography variant="subtitle2" color="">&nbsp; {data?.customer_type} </Typography>
            </Grid>
            <Grid item md={4} xs={12} display={"flex"}>
                <Typography variant="inherit" color="" width={80} >Amount : </Typography>
                <Typography variant="subtitle2" color="">&nbsp; {data?.total_amount} </Typography>
            </Grid>
            <Grid item md={4} xs={12} display={"flex"}>
                <Typography variant="inherit" color="" width={65} >Date : </Typography>
                <Typography variant="subtitle2" color="">&nbsp; {data?.invoice_date} </Typography>
            </Grid>
        </Grid>
        <Grid container pb={5} bgcolor={'white'}> 
            <Grid item lg={9} md={11} xs={12} sm={12}>
                <Typography variant="body1" fontWeight={500} color="initial" sx={{margin : "12px 0px 4px 15px"}}>Amount Breakdown</Typography>
            <TableContainer sx={{border : "1px solid rgba(0,0,0,0.3)",margin  :"10px",borderRadius : "4px"}} >
            <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                <TableHead>
                <TableRow>
                    <TableCell>Charge Name</TableCell>
                    <TableCell align="right">Taxable Amount</TableCell>
                    <TableCell align="right">IGST</TableCell>
                    <TableCell align="right">CGST</TableCell>
                    <TableCell align="right">SGST</TableCell>
                    <TableCell align="right">Total Amount</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="right">{row.charge_name}</TableCell>
                    <TableCell align="right">{row.taxable_amt}</TableCell>
                    <TableCell align="right">{row.igst_amount}</TableCell>
                    <TableCell align="right">{row.cgst_amount}</TableCell>
                    <TableCell align="right">{row.sgst_amount}</TableCell>
                    <TableCell align="right">{row.total_amount}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            </Grid>
            <Grid md={8} xs={6}></Grid>
            {name === 'crn' ? (
                <Grid item md={12} display={"flex"} gap={2} alignItems={"center"} p={2}>
                    <div>
                    <Typography variant="subtitle1" color="initial" width={210}>Enter CRN Amount </Typography>
                    <Typography variant="subtitle2" color="initial" >Available Amount  : <span style={{color : "green"}}>
                        ₹ {rows[0]?.available_amount}
                        </span></Typography>
                    </div>
                    <div>
                    <TextField
                        variant="outlined"
                        className="form_style"
                        label="Amount*"
                        type="number"
                        size="small"
                        name="inputAmount"
                        value={inputAmount}
                        error={error && error["total_amount"]}
                        onChange={handleChangeAmount}
                        width={"100px"}
                        />
                        <FormHelperText style={{ color: "red", marginLeft: "4px" }}>
                            {error && error["total_amount"]}
                        </FormHelperText>
                    </div>
                    <div>
                    <FormControl style={{width : "250px",marginBottom : "4px"}} size='small'>
                      <InputLabel id="demo-simple-select-label">Mode of Payment</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className='form_style'
                        value={modeofpayement}
                        label="Mode of Payment"
                        fullWidth
                        onChange={handleModeofpayement}
                      >
                        <MenuItem value={1}>Offline</MenuItem>
                        <MenuItem value={2}>Online</MenuItem>
                      </Select>
                    </FormControl>
                    </div>
                </Grid>
            ) : (
                <>
                <Grid item md={5}></Grid>
                <Grid item md={6} xs={9} display={"flex"} gap={1} alignItems={"center"}  p={2}>
                    <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        className='form_style'
                        defaultValue={dayjs(invoiceDate, 'DD-MM-YYYY')}
                        format='DD MMM YYYY'
                        slotProps={{
                          textField: {
                            size: 'small',
                          },
                        }}
                        name='departureDate'
                        // value={departureDate}
                        // onChange={(newValue) => setDepartureDate(newValue.$d)}
                        
                      />
                    </LocalizationProvider>
                  </div>
                  <FormControl fullWidth size='small' >
                    <InputLabel>Address</InputLabel>
                    <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        fullWidth
                        size='small'
                        label='Address'
                        
                        className='form_style'
                        // name='passengerId'
                        // onChange={(e) => setSelectedAddress(e.target.value)}
                        >
                        {addressList.map((item) => (
                            <MenuItem key={item.id} value={item}>
                            {item.address_label}
                        </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
             </>
            )}
        </Grid>
    </DashboardLayout>
  )
}

export default CreateCRN