import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AddCard from "@mui/icons-material/AddCard";
import FlightIcon from '@mui/icons-material/Flight';
import DescriptionIcon from '@mui/icons-material/Description';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { AddCircle,ReceiptLong, AdminPanelSettings, AirplaneTicket, AssignmentInd, ConfirmationNumber, ListAlt, ManageAccounts, Payments, PendingActions, PostAdd, EventAvailable, Add, Assessment, } from '@mui/icons-material';

export const userSideLinks = [
  {
    id:1343,
    icon:<ConfirmationNumber fontSize="small"/>,
    path: "/dashboard",
    name: "Create Query",
  },
  {
    id:1,
    icon:<AccountBoxIcon fontSize="small"/>,
    path: "/profile",
    name: "Profile",
  },
  {
    id:2,
    icon :<AddCard fontSize="small"/>,
    path: "query-list",
    name: "All Query",
  },
  {
    id:4,
    icon:<DriveFileRenameOutlineIcon fontSize="small"/>,
    path: "booking",
    name: "My Booking",
  },
  {
    id:6,
    icon : <DescriptionIcon fontSize="small"/>,
    path: "policy",
    name: "Booking Policy",
  },
  {
    id:5,
    icon : <DescriptionIcon fontSize="small"/>,
    path: "terms",
    name: "Terms & Conditions",
  },
];

export const adminSideLinks = [
  {
    id:1,
    icon : <AccountBoxIcon fontSize="small"/>,
    path: "/dashboard",
    name: "Dashboard",
  },
  {
    id:1343,
    icon:<ConfirmationNumber fontSize="small"/>,
    path: "/create-query",
    name: "Create Query",
  },
  {
    id:20,
    icon : <ListAlt fontSize="small"/>,
    path: "/query-list",
    name : "Query List"
  },
  {
    id:43434,
    icon:<AirplaneTicket fontSize="small"/>,
    path: "/booking",
    name: "All Booking",
  },

  {
    id:1324,
    icon : <AccountBoxIcon fontSize="small"/>,
    path: "/customer-flightbooking",
    name: "Customer Bookings",
  },

  {
    id:5,
    icon : <DriveFileRenameOutlineIcon fontSize="small"/>,
    path: "/all-leads",
    name : "All Leads"
  },
  {
    id:52,
    icon : <AddCircle fontSize="small"/>,
    path: "/add-designation",
    name : "Designation"
  },
  {
    id:50,
    icon : <AssignmentInd fontSize="small"/>,
    path: "/emp-management",
    name : "All Employee"
  },
  {
    id:7,
    icon : <ManageAccounts fontSize="small"/>,
    path: "/user-management",
    name : "Agent Management"
  },
  {
    id:1378332,
    icon : <Assessment fontSize="small"/>,
    path: "/seat-inventory",
    name: "Seat Inventory Report",
  },
  {
    id:3,
    icon : <FlightIcon fontSize="small"/> ,
    path: "/flight-info",
    name : "Flight Info"
  },
  {
    id:2121,
    icon : <FlightIcon fontSize="small"/> ,
    path: "/editcalendar",
    name : "Set Seat Availability"
  },
  
  
];

export const salesSideLinks = [
  {
    id:1,
    icon : <AccountBoxIcon fontSize="small"/>,
    path: "/dashboard",
    name: "Dashboard",
  },
  {
    id:1343,
    icon:<ConfirmationNumber fontSize="small"/>,
    path: "/create-query",
    name: "Create Query",
  },
  {
    id:205,
    icon : <ListAlt fontSize="small"/>,
    path: "/query-list",
    name : "Query List"
  },
  {
    id:43453,
    icon:<AirplaneTicket fontSize="small"/>,
    path: "booking",
    name: "All Booking",
  },
  {
    id:5,
    icon : <DriveFileRenameOutlineIcon fontSize="small"/>,
    path: "/all-leads",
    name : "All Leads"
  },
  {
    id:20,
    icon : <ManageAccounts fontSize="small"/>,
    path: "/user-list",
    name : "Agent List"
  },
  {
    id:3,
    icon : <FlightIcon fontSize="small"/> ,
    path: "/flight-info",
    name : "Flight Info"
  },
  {
    id:3131,
    icon : <EventAvailable fontSize="small"/> ,
    path: "/editcalendar",
    name : "Set Seat Availability"
  }
];


export const financeSideLinks = [
  {
    id:1,
    icon : <AccountBoxIcon fontSize="small"/>,
    path: "/dashboard",
    name: "Dashboard",
  },
  {
    id:43453,
    icon:<ReceiptLong fontSize="small"/>,
    path: "invoices",
    name: "Invoices",
  },
  {
    id:4,
    icon:<PostAdd fontSize="small"/>,
    path: "additional-invoice",
    name: "Additional Invoice",
  },
  {
    id:133,
    icon : <AccountBoxIcon fontSize="small"/>,
    path: "/all-charges",
    name: "All Charges",
  },
  {
    id: 10650,
    icon : <AddCard fontSize="small"/>,
    path : "/charge-list",
    name : "Charge Head"
  },

  
  {
    id: 11,
    icon : <AirplaneTicket fontSize="small"/>,
    path : "/booking-payment",
    name : "Verify Payment"
  },
  {
    id: 1232,
    icon : <AirplaneTicket fontSize="small"/>,
    path : "/create-ledger",
    name : "Create Ledger"
  },
];


export const sidebarIcon = {
  "Create Query" : <ConfirmationNumber fontSize="small"/>,
  "Profile" : <AccountBoxIcon fontSize="small"/>,
  "All Query" : <AddCard fontSize="small"/>,
  "All Charges" : <AddCard fontSize="small"/>,
  "My Booking" : <DriveFileRenameOutlineIcon fontSize="small"/>,
  "All Leads" : <DriveFileRenameOutlineIcon fontSize="small"/>,
  "Booking Policy" : <DescriptionIcon fontSize="small"/>,
  "Terms & Conditions" : <DescriptionIcon fontSize="small"/>,
  "Query List" :  <ListAlt fontSize="small"/>,
  "All Booking" : <AirplaneTicket fontSize="small"/>,
  "Verify Payment" : <AirplaneTicket fontSize="small"/>,
  "Designation" :  <AddCircle fontSize="small"/>,
  "All Employee" : <AssignmentInd fontSize="small"/>,
  "Agent Management" : <ManageAccounts fontSize="small"/>,
  "Dashboard" : <AccountBoxIcon fontSize="small"/>,
  "Flight Info" : <FlightIcon fontSize="small"/> ,
  "Invoices" : <ReceiptLong fontSize="small"/>,
  "Additional Invoice" : <PostAdd fontSize="small"/>,
}

