import * as React from 'react'
import { alpha, styled } from '@mui/material/styles'
import {
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  gridClasses,
  GridToolbar,
  GridPagination,
} from '@mui/x-data-grid'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import {
  ArrowRightAlt,
  Cancel,
  Delete,
  Download,
  Edit,
  Info,
  Inventory,
  List,
  Save,
  SyncAlt,
  Upload,
} from '@mui/icons-material'
import {
  Button,
  Tooltip,
  Grid,
  Popover,
  Typography,
  Card,
  IconButton,
  CardContent,
  TextField,
  Modal,
  Box,
} from '@mui/material'

import dayjs from 'dayjs'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { CalendarIcon } from '@mui/x-date-pickers'
import { SnackbarContext } from '../../../../context/Snackbar'
import { getFlightListApi } from '../../../../services/admin.configService'
import {
  getCustomerbooking,
  getFlightInventorydetailsApi,
  getSeatinventoryreport,
} from '../../../../services/configService'
import TableMenu from '../../../../components/table_modal/TableMenu'

const ODD_OPACITY = 0.2

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${gridClasses.row}.void`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.cancel`]: {
    backgroundColor: '#f123',
  },
  [`& .${gridClasses.row}.green`]: {
    animation: 'ripple 3s ease-in-out',
  },
  '@keyframes ripple': {
    '0%': {
      backgroundColor: 'lightgreen',
    },
    '100%': {
      backgroundColor: 'white',
    },
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeader}`]: {
    color: 'white',
    width: '100%',
    backgroundColor: 'lightslategrey',
  },
}))

const CustomFooter = () => {
  return (
    <Grid
      container
      gap={2}
      paddingLeft={2}
      // height={"50px"}
      alignItems={'center'}
      justifyContent={'space-between'}
      borderTop={'1px solid #e5e5e5'}
    >
      <Grid item display={'flex'} gap={1}>
        <Typography variant='subtitle1' color='initial'>
          Tags
        </Typography>
        <Stack direction={'row'} spacing={1}>
          <Chip
            label='Not Available'
            sx={{ backgroundColor: '#f123', width: '100%' }}
          />
          <Chip
            label='(E) Economy Seat'
            sx={{ backgroundColor: '#2376', width: '100%', color: 'white' }}
          />
          <Chip
            label='(B) Business Seat'
            sx={{ backgroundColor: '#2376', width: '100%', color: 'white' }}
          />
        </Stack>
      </Grid>
      <GridPagination />
    </Grid>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  bgcolor: 'background.paper',
  background: '#edf1f4',

  boxShadow: 24,
  p: 2,
}

export default function Seatinventorylisting() {
  const { setOn, setSeverity, setMes } = React.useContext(SnackbarContext)
  const [bookings, setBookings] = React.useState([])
  const [openmodal, setOpenmodal] = React.useState(false)
  const [flightInventory, setFlightInventory] = React.useState([])
  const [preflightInventory, setPreflightInventory] = React.useState([])

  const handleClosemodal = () => setOpenmodal(false)

  async function getInventoryList() {
    try {
      const res = await getSeatinventoryreport()
      if (res !== 204 && res !== 401) {
        setBookings(res.data)
        console.log('@!@!!@', res.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  async function getFlightInventorydetails(id) {
    try {
      const res = await getFlightInventorydetailsApi(id)
      if (res !== 204) {
        setFlightInventory(res.data[0] ?? [])
        setPreflightInventory(res.data[1] ?? [])
      }
    } catch (error) {
      console.log(error)
    }
  }
  console.log('hfdssdhsa', preflightInventory, flightInventory)
  const handleOpenmodal = (row) => {
    setOpenmodal(true)
    const code =
      dayjs(row?.d_date, 'DD-MM-YYYY').format('YYYY-MM-DD') + row?.flight_code
    getFlightInventorydetails(code)
    console.log(code)
  }

  console.log('flightInventory', flightInventory)

  React.useEffect(() => {
    getInventoryList()
    // getFlightList()
  }, [])
  const columns = [
    { field: 'id', headerName: 'ID', width: 40 },
    {
      field: 'flight_code',
      headerName: 'Flight code',
      // flex: 1,
      width: 140,
      renderCell: (params) => (
        <div>
          {params.row.flight_code}{' '}
          <p style={{ color: '#318CE7', fontWeight: '500' }}>
            ({params.row.flight_departure_from} To{' '}
            {params.row.flight_departure_to})
          </p>
        </div>
        // console.log("params",params)
      ),
    },
    { field: 'd_date', headerName: 'Date', width: 130 },
    // {
    //   field: 'status',
    //   headerName: 'Status',
    //   flex: 1,
    //   width: 140,
    //   renderCell: (params) =>
    //     params.row.is_available === 1 ? (
    //       <Chip label='Available' variant='outlined' color='success' />
    //     ) : (
    //       <Chip label='Not Available' variant='outlined' color='error' />
    //     ),
    // },
    {
      field: 'e_total_seat',
      headerName: '(E) Total',
      width: 100,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(E)</p>
          <p>Total</p>
        </div>
      ),
    },
    {
      field: 'b_total_seat',
      headerName: '(B) Total',

      width: 100,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(B)</p>
          <p>Total</p>
        </div>
      ),
    },
    {
      field: 'e_hold_without_adv',
      headerName: '(E) Without adv',

      width: 130,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(E)</p>
          <p>Without adv</p>
        </div>
      ),
    },
    {
      field: 'b_hold_without_adv',
      headerName: '(B) Without adv',

      width: 130,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(B)</p>
          <p>Without adv</p>
        </div>
      ),
    },
    {
      field: 'e_hold_with_adv',
      headerName: '(E) With adv',

      width: 120,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(E)</p>
          <p>With adv</p>
        </div>
      ),
    },
    {
      field: 'b_hold_with_adv',
      headerName: '(B) With adv',

      width: 120,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(B)</p>
          <p>With adv</p>
        </div>
      ),
    },
    {
      field: 'e_confirmed_seat',
      headerName: '(E) Confirmed',

      width: 130,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(E)</p>
          <p>Confirmed</p>
        </div>
      ),
    },
    {
      field: 'b_confirmed_seat',
      headerName: '(B) Confirmed',
      width: 130,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(B)</p>
          <p>Confirmed</p>
        </div>
      ),
    },
    {
      field: 'e_available_seat',
      headerName: '(E) Available',

      width: 130,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(E)</p>
          <p>Available</p>
        </div>
      ),
    },
    {
      field: 'b_available_seat',
      headerName: '(B) Available ',
      width: 130,
      renderHeader: (params) => (
        <div style={{ display: 'flex', gap: 2, fontWeight: 500 }}>
          <p style={{ color: '#ADFF2F' }}>(B)</p>
          <p>Available</p>
        </div>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 75,
      cellClassName: 'actions',
      getActions: ({ row }) => {
        return [
          <>
            <TableMenu
              data={[
                {
                  id: 1,
                  title: 'Inventory flight Details',
                  icon: <Inventory fontSize='small' color='success' />,
                  // disable: row?.pdf ? true : false,
                  fn: () => handleOpenmodal(row),
                },
              ]}
            />
          </>,
        ]
      },
    },
  ]

  const detailscolumns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'query_code', headerName: 'Query Code', flex: 1, minWidth: 130 },
    {
      field: 'quoted_date',
      headerName: 'Quoted Date',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.quoted_date
          ? dayjs(params.row.quoted_date).format('DD-MM-YYYY')
          : 'N.A'
      },
    },
    {
      field: 'depart_date',
      headerName: 'Departure Date',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return params.row.depart_date
          ? dayjs(params.row.depart_date).format('DD-MM-YYYY')
          : 'N.A'
      },
    },
    { field: 'total_fare', headerName: 'Total Amount', flex: 1, minWidth: 130 },
    {
      field: 'balance_amount',
      headerName: 'Balance Amount',
      flex: 1,
      minWidth: 130,
    },
    { field: 'flight_no', headerName: 'Flight Code', flex: 1, minWidth: 130 },
    // {
    //   field: 'tp',
    //   headerName: 'Total Passenger',
    //   flex: 1,
    //   minWidth: 130,
    //   renderCell: (params) => {
    //     <div>
    //       <p>
    //   {    params.row.e_avail_seat}
    //       </p>
    //     </div>
    //   },
    // },
  ]

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 9rem)',
        backgroundColor: 'white',
      }}
    >
      <StripedDataGrid
        loading={false} // Set loading to false or true depending on your needs
        rows={bookings?.map((item, index) => ({
          ...item,

          id: index + 1,
        }))}
        getRowId={(row) => row?.id}
        columns={columns}
        getRowClassName={(params) =>
          params.row.is_available == 0
            ? 'void'
            : params.indexRelativeToCurrentPage % 2 === 0
            ? 'even'
            : 'odd'
        }
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar, footer: CustomFooter }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      <Modal
        open={openmodal}
        // onClose={handleClosemodal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={style}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              <Typography
                id='modal-modal-title'
                sx={{ fontSize: '19px', fontWeight: 510 }}
              >
                Details
              </Typography>
              <Typography
                id='modal-modal-description'
                sx={{ fontSize: '15px', color: '#A9A9A9' }}
              >
                Inventory Flight Details
              </Typography>
            </div>
            <IconButton aria-label='delete' onClick={handleClosemodal}>
              <Cancel />
            </IconButton>
          </div>

          <CardContent
            sx={{ borderRadius: 2, mt: 1, padding: 0, background: 'white' }}
          >
            <StripedDataGrid
              loading={false} // Set loading to false or true depending on your needs
              rows={flightInventory?.map((item, index) => ({
                ...item,

                id: index + 1,
              }))}
              columns={detailscolumns}
              getRowClassName={(params) =>
                params.row.is_available == 1
                  ? 'void'
                  : params.indexRelativeToCurrentPage % 2 === 0
                  ? 'even'
                  : 'odd'
              }
            />
          </CardContent>
        </Card>
      </Modal>
    </div>
  )
}
